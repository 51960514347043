import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Content, { HTMLContent } from "../components/Content";

import "./pathologies-index.scss";
import PathologieRoll from "../components/PathologieRoll";

export const PathologiesIndexTemplate = ({
  image,
  title,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <div className="PathologiesIndex">
      <div className="Layout__header">
        {image && (
          <PreviewCompatibleImage
            className="PathologiesIndex__picture"
            imageInfo={{
              image: image,
              alt: `Header banner ${title}`,
            }}
          />
        )}
        <h1>{title}</h1>
      </div>
      <div className="Layout__body">
        <PostContent
          className="Markdown PathologiesIndex__description"
          content={content}
        />
        <PathologieRoll />
      </div>
    </div>
  );
};

const PathologiesIndex = ({ data = {} }) => {
  const { markdownRemark } = data || {};
  const { frontmatter, html } = markdownRemark || {};
  const { image, title } = frontmatter || {};
  return (
    <Layout>
      <PathologiesIndexTemplate
        content={html}
        contentComponent={HTMLContent}
        image={image}
        title={title}
      />
    </Layout>
  );
};

PathologiesIndex.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PathologiesIndex;

export const PathologiesIndexQuery = graphql`
  query PathologiesIndex {
    markdownRemark(frontmatter: { templateKey: { eq: "pathologies-index" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
