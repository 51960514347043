import React, { useState, useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import "./PathologieRoll.scss";

const DISPLAY_PATHOLOGIE_TYPE = {
  main: "Main",
  poignet: "Poignet",
  coude: "Coude",
  epaule: "Épaule",
};

const PathologieRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const [inputValue, setInputValue] = useState("");
  const [hasAnwser, setHasAnswer] = useState(true);
  const [postList, setPostList] = useState(posts);

  const handleOnChange = () => {
    const filteredPosts = posts.reduce(
      (acc, cur) => {
        const { node } = cur || {};
        const { frontmatter } = node || {};
        const { description, name, shortname, tags = [], bodypart } =
          frontmatter || {};
        const fitInTags = tags.some((tag) =>
          tag
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(inputValue)
        );
        if (
          fitInTags ||
          bodypart
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(inputValue) ||
          description
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(inputValue) ||
          (shortname || "")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(inputValue) ||
          name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(inputValue)
        ) {
          return {
            ...acc,
            [bodypart]: acc[bodypart] ? [...acc[bodypart], node] : [node],
          };
        }
        return acc;
      },
      { main: [], poignet: [], coude: [], epaule: [] }
    );
    setPostList(filteredPosts);
    const hasAnwser = Object.keys(filteredPosts).some(
      (key) => filteredPosts[key].length > 0
    );
    setHasAnswer(hasAnwser);
  };

  useEffect(() => {
    handleOnChange();
  }, [inputValue]);
  useEffect(() => {
    handleOnChange();
  }, []);

  return (
    <div className="PathologieRoll">
      <span className="PathologieRoll__SearchBarIcon"></span>
      <input
        className="PathologieRoll__SearchBar"
        placeholder="Rechercher pathologies"
        onChange={(e) =>
          setInputValue(
            e.target.value
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
        }
      />
      <div className="PathologieRoll__categoryList">
        {hasAnwser ? (
          Object.keys(postList).map((pathologieType) => {
            return postList[pathologieType] &&
              postList[pathologieType].length > 0 ? (
              <div
                className="PathologieRoll__category"
                key={`pathologie-category-${pathologieType}`}
              >
                <h2 className="PathologieRoll__categoryTitle">
                  {DISPLAY_PATHOLOGIE_TYPE[pathologieType]}
                </h2>
                <ul className="PathologieRoll__pathologieList">
                  {postList[pathologieType].map((post) => {
                    const { id, frontmatter, fields } = post || {};
                    const { shortname = "", name, emergency = false } =
                      frontmatter || {};
                    const { slug } = fields || {};
                    const showName = shortname || name;
                    return (
                      <li
                        key={id}
                        className={classnames(
                          "PathologieRoll__pathologieListItem",
                          { "is-emergency": emergency }
                        )}
                      >
                        <Link to={slug}>{showName}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null;
          })
        ) : (
          <span>
            Il semble qu'il n'y ai rien qui corresponde à votre recherche
          </span>
        )}
      </div>
    </div>
  );
};

PathologieRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query PathologieQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "pathologie" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                name
                shortname
                role
                bodypart
                description
                emergency
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PathologieRoll data={data} count={count} />}
  />
);
